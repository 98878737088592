export default {
  darkBlue: '#19243b',
  fiord: '#3d4b66',
  oxfordBlue: '#3e475b',
  riverBed: '#444c5c',
  regentGray: '#808ba5',
  blueGrey: '#808ba5',
  cadetBlue: '#99a4bd',
  heather: '#b4bbcb',
  athensGray: '#e7ebee',
  mischka: '#dee0e7',
  titanWhite: '#fafaff',
  white: '#fff',
  radicalRed: '#ff3e55',
  greenHaze: '#0aad56',
  lightGreen: '#00d664',
  whiteLilca: '#fcfcfe',
  blackSqueeze: '#f5f9fb',
};
