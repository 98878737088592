import React, { Component } from 'react';
import Menu from './Menu';
import MenuTrigger from './MenuTrigger';
import { Navbar, NavWrapper, LogoWrapper, GooglePartner } from './Navigation.style';

import logoImage from '../../assets/logo.svg';
import logoGoogle from '../../assets/Partner-RGB.png';

const NavContext = React.createContext();

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      isSticky: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.stickNavigation);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickNavigation);
  }

  stickNavigation = () => {
    this.setState({
      isSticky: window.pageYOffset > 0,
    });
  }

  triggerMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  };

  render() {
    const { isMenuOpen, isSticky } = this.state;

    return (
      <Navbar isSticky={ isSticky }>
        <NavWrapper>
          <LogoWrapper>
            <a href="/">
              <img src={ logoImage } alt="Yupi-There" />
            </a>
            <GooglePartner href="https://www.google.com/partners/agency?id=2501485851" target="_blank">
              <img src={ logoGoogle }/>
            </GooglePartner>
          </LogoWrapper>
          <script src="https://apis.google.com/js/platform.js" async defer></script>
          <div class="g-partnersbadge" data-agency-id="2501485851">
          </div>
          <NavContext.Provider value={{ menuTrigger: this.triggerMenu }}>
            <Menu isActive={isMenuOpen} />
          </NavContext.Provider>
          <MenuTrigger isActive={isMenuOpen} onClick={this.triggerMenu} />
        </NavWrapper>
      </Navbar>
    );
  }
}

export { NavContext };
export default Navigation;
